<script setup lang="ts">
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
})

const icons = import.meta.glob('@/components/ui/icons/**/*.vue')

const iconComponent = ref(null)

const getIconPath = (iconName: string) => {
  const formattedIconName = iconName
    .split(/(?=[A-Z])/)
    .join('/')
    .toLowerCase()

  for (const path in icons) {
    const fileName = path.split('/').pop().replace('.vue', '').toLowerCase()

    if (formattedIconName === fileName) {
      return path
    }
  }
  return null
}

const loadIconComponent = async (iconName: string) => {
  const iconPath = getIconPath(iconName)
  if (iconPath && icons[iconPath]) {
    const module = await icons[iconPath]()
    iconComponent.value = markRaw(module.default)
  }
  else {
    iconComponent.value = null
  }
}

watchEffect(() => {
  loadIconComponent(props.icon)
})
</script>

<template>
  <component
    :is="iconComponent"
    v-if="iconComponent"
  />
</template>
